<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="会员ID">
        <el-input v-model="id" placeholder="请输入会员ID"></el-input>
      </el-form-item>
      <el-form-item label="微信昵称">
        <el-input v-model="NickName" placeholder="请输入微信昵称"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="Phone" placeholder="请输入手机号"></el-input>
      </el-form-item>

      <el-form-item label="会员等级">
        <el-select v-model="gradeno" placeholder="请选择" clearable>
          <el-option v-for="item in gradelist" :key="item.gradeno" :label="item.name" :value="item.gradeno"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="listData" border style="width: 100%">
        <el-table-column prop="id" label="ID" align="center" width="60"></el-table-column>
        <el-table-column label="头像" width="110" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.headPortrait">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="真实姓名" align="center"></el-table-column>
        <el-table-column prop="nickName" label="微信昵称" align="center"></el-table-column>
        <el-table-column prop="gradename" label="等级" align="center"></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center"></el-table-column>

        <el-table-column prop="addDate" label="注册时间" align="center" width="160px"></el-table-column>

        <el-table-column prop="integral" label="剩余积分" align="center">
          <template slot-scope="scope">
            <el-link :underline="false" @click="godetail(scope.row.id)" type="primary">{{ scope.row.integral }}
            </el-link>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="213" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="toEdit(scope.row.id)">编辑</el-button>
            <!-- <el-button size="mini" @click="gousercoupon(scope.row.id)">赠送券</el-button> -->
            <el-button type="danger" size="mini" @click="onDelete(scope.row.id)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :page-size="10" :current-page="pageIndex" :total="pageTotal"
        @current-change="onCurrentChange" />
    </div>

    <el-dialog title="充值" :visible.sync="dialogTableVisible" width="40%">
      <el-divider></el-divider>
      <el-form :model="form">
        <el-form-item label>
          <el-radio-group v-model="form.nature">
            <el-radio :label="3">充值</el-radio>

            <el-radio :label="4">扣除</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="充值积分">
          <el-input-number v-model="form.payMoney"></el-input-number>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="onpay()">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListRequest,
  addczRequest,
  setStatesRequest,
  delRequest, getgradelist
} from '@/api/wxuser'
import { getdicdtabykeyRequest } from '@/api/sysdicdata'
export default {
  name: 'Userlist',
  data() {
    return {
      UserAccount: '',
      NickName: '',
      Phone: '',
      gradeno: '',
      email: '',
      listData: [],
      pageIndex: 1,
      pageTotal: 0,
      vipdata: [],
      vipdata2: [],
      vipgrade: -1,
      id: '',
      dialogTableVisible: false,
      row: {},
      gradelist: [],
      form: { userid: 0, nature: 3, payMoney: 0, remark: '' },
    }
  },
  created() {
    this.getgradelist()
    this.getList()
  },
  methods: {
    getList() {
      getListRequest({
        gradeno: this.gradeno,
        id: this.id,
        vipgrade: this.vipgrade,
        UserAccount: this.UserAccount,
        NickName: this.NickName,
        email: this.email,
        Phone: this.Phone,
        pageNum: this.pageIndex,
        pageSize: 10,
      }).then((res) => {
        let data = res.data
        this.listData = data.data
        this.pageTotal = data.count
      })
    },
    getgradelist() {
      getgradelist().then((res) => {
        this.gradelist = res.data
      })
    },


    oncz(id, row) {
      console.info(id)
      this.form.userid = id
      this.row = row
      this.dialogTableVisible = true
    },
    onpay() {
      addczRequest(this.form).then((res) => {
        let data = res.data
        if (data.code == -1) {
          this.$message({
            type: 'error',
            message: data.msg,
          })
        } else {
          this.dialogTableVisible = false
          this.row.integral = data.integral
        }
      })
    },
    godetail(id) {
      this.$router.push({
        path: '/wx/user/integral',
        query: { id: id },
      })
    },

    gousercoupon(id) {
      this.$router.push({
        path: '/wx/user/usercoupon',
        query: { id: id },
      })
    },

    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states,
      }).then((res) => {
        row.states = states
      })
    },
    toEdit(id) {
      this.$router.push(`/wx/user/edit/${id}`)
    },
    onDelete(id) {
      this.$confirm('确认删除该用户吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 调用删除接口
          return delRequest({ id })
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: `删除成功！`,
          })
          this.getList()
        })
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
  },
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.table-row-image {
  width: 80px;
  height: auto;
}
</style>
