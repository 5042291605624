import request from '@/utils/request'

// 获取列表
export function getListRequest(params) {
  return request({
    url: '/admin/SysWxMemberUser/getList',
    method: 'get',
    params
  })
}

// 获取详情信息
export function getdatilRequest(params) {
  return request({
    url: '/admin/SysWxMemberUser/getdatil',
    method: 'get',
    params
  })
}

// 修改信息
export function editRequest(data) {
  return request({
    url: '/admin/SysWxMemberUser/edit',
    method: 'post',
    data
  })
}

// 删除信息
export function delRequest(params) {
  return request({
    url: '/admin/SysWxMemberUser/del',
    method: 'get',
    params
  })
}

// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/SysWxMemberUser/setStates',
    method: 'get',
    params
  })
}

// 会员余额流水
export function getBalanceListRequest(params) {
  return request({
    url: '/admin/SysWxMemberUserBalance/getList',
    method: 'get',
    params
  })
}
// 会员余额流水
export function addczRequest(params) {
  return request({
    url: '/admin/SysWxMemberUserBalance/addcz',
    method: 'get',
    params
  })
}




// 会员积分流水
export function getIntegrListRequest(params) {
  return request({
    url: '/admin/SysWxMemberUserIntegra/getList',
    method: 'get',
    params
  })
}


// 师傅审核
export function auditMasterRequest(params) {
  return request({
    url: '/admin/SysWxMemberUser/auditMaster',
    method: 'get',
    params
  })
}

// 
export function getgradelist(params) {
  return request({
    url: '/admin/SysWxMemberUser/getgradelist',
    method: 'get',
    params
  })
}
export function getdatilgrade(params) {
  return request({
    url: '/admin/SysWxMemberUser/getdatilgrade',
    method: 'get',
    params
  })
}

export function updateusergradeByID(params) {
  return request({
    url: '/admin/SysWxMemberUser/updateusergradeByID',
    method: 'get',
    params
  })
}
